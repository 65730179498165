import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { RootReducerState } from 'src/app/store';
import { Store } from '@ngrx/store';
import { updateNotification } from 'src/app/store/chatNotification/chat-notification.action';
import { ToastrService } from 'ngx-toastr';
import { addNofificationChatEmployee } from 'src/app/store/chatEmployee/chat-employee.action';
import { PesonalChatSocketService } from './common-services/personalchat.socket.service';

@Injectable({
  providedIn: 'root',
})
export class FcmService {
  currentActiveRoomId: string = '';
  notificationAvailable: boolean = true;

  constructor(
    private afMessaging: AngularFireMessaging,
    private http: HttpClient,
    public store: Store<RootReducerState>,
    private _toasterService: ToastrService,
    private _chatService: PesonalChatSocketService

  ) {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data && event.data.type === 'notification-data') {
          this.handleNotificationUpdate(event.data.payload);
        }
      });
    }
    this.showWelcomeNotification();

  }
  showWelcomeNotification() {
    if ('Notification' in window) {
      // Check if notifications are denied
      if (Notification.permission === 'denied') {
        this.notificationAvailable = false;
        return;
      }

      // Check if permission is already granted
      if (Notification.permission === 'granted') {
        this.notificationAvailable = true;
      } else if (Notification.permission === 'default') {
        // Request notification permission
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            this.notificationAvailable = true;

          } else {
            this.notificationAvailable = false;
          }
        });
      }
    } else {
      this.notificationAvailable = false;
      console.warn('Browser does not support notifications.');
    }
  }


  requestPermission(): Observable<any> {
    return new Observable((observer) => {
      this.afMessaging.requestToken.subscribe(
        (token) => {
          observer.next(token);
        },
        (error) => {
          console.error('Permission denied or error occurred:', error);
          observer.error(error);
        }
      );
    });
  }

  // registerTokenOnBackend(token: string): void {
  //   const apiUrl = 'https://your-backend-api.com/register-device';
  //   this.http.post(apiUrl, { token }).subscribe(
  //     (response) => {
  //       console.log('Device registered successfully:', response);
  //     },
  //     (error) => {
  //       console.error('Error registering device:', error);
  //     }
  //   );
  // }

  listenForMessages(): Observable<any> {
    return this.afMessaging.messages;
  }

  // listenForMessages(): Observable<any> {
  //   return new Observable((observer) => {
  //     this.afMessaging.messages.subscribe(
  //       (message) => {
  //         console.log('Foreground message received:', message);
  //         observer.next(message);
  //       },
  //       (error) => {
  //         console.error('Error while listening for messages:', error);
  //         observer.error(error);
  //       }
  //     );
  //   });
  // }

  updateRoomId(roomId: string): void {
    this.currentActiveRoomId = roomId;
  }
  getRoomId() {
    return this.currentActiveRoomId;
  }

  handleNotificationUpdate(payload: any) {
    try {
      const audio = new Audio('assets/sound/mixkit-fairy-message-notification-861.wav');
      audio.play().catch((error) => {
        console.error('Audio playback failed:', error);
      });
    } catch (error) {
      console.error('Audio initialization failed:', error);
    }
    try {
      if (this.currentActiveRoomId && this.currentActiveRoomId == payload.data.roomId) {
        // console.log("CRA", this.currentActiveRoomId);
      } else {
        if (payload.data.contentType == 'image') {
          payload.data.content = 'sent you a image';
        }
        if (payload.data.contentType == 'file') {
          payload.data.content = 'sent you a file';
        }
        if (this.notificationAvailable) {
          const notification = new Notification(payload.data.body, {
            body: payload.data.content,
            icon: 'https://cdn-icons-png.flaticon.com/512/561/561127.png', 
            requireInteraction: true,
          });
          setTimeout(() => {
            notification.close();
          }, 4000);
          notification.onclick = () => {

            window.open('/chat', '_blank');
            notification.close();
          };
        } else {
          this._toasterService.info(
            payload.data.content,
            payload.data.firstName
          );
        }

        if (payload.data.type && payload.data.type == 'group') {
          this._chatService.updateGroupNotification(payload.data.content, payload.data.roomId);
        } else {
          this.store.dispatch(updateNotification({ notification: payload }));
          this.store.dispatch(addNofificationChatEmployee({ notification: payload }));
        }
      }


    } catch (err) {
      console.log("error", err);
    }
  }
}
