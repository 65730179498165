import { Action, createReducer, on } from '@ngrx/store';
import { updateNotification  , makeNotificationRead, insertNotification } from './chat-notification.action';
import { FirebaseMessage } from './chat-notification.model';


export const initialState: FirebaseMessage[] = [];

export const ChatNotificationReducer = createReducer(
    initialState,
    on( updateNotification , (state, { notification }) => {
       //console.log("S N U")
        let newState = state ;
        let checkExist =  newState.some((item: FirebaseMessage)=>{
                  if(item.data.roomId == notification.data.roomId){
                    item.count++ ;
                    item.data  =  notification.data  ;
                    return true ;
                  }
        });
        if(!checkExist){
              notification.count =  1 ;
              newState =  [ ...newState , notification ] ;
        }
        return  newState ;
      }) ,
      on( makeNotificationRead , (state, { roomId }) => {
    
        //console.log("S N R")

        let newState  =  state.filter((item: FirebaseMessage)=>{
               if(item.data.roomId !=  roomId){
                  return  item ;
               }
        });
        return [ ...newState ];
      }) ,
      on(insertNotification , (state , { notification })=>{
        //console.log("S N A")
           return  notification  ;
      })
);

export function reducer(state: FirebaseMessage[] | undefined, action: Action) {
    return ChatNotificationReducer(state, action);
}
