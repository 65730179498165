import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";

import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Observable, Subject, Subscriber } from "rxjs";
import { IApiResponse, IQueryParams } from "./models";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class HttpServiceNode {
  private baseUrl: string;
  private headers: HttpHeaders;
  private baseUrlSet: boolean = false;
  private conditionSource = new BehaviorSubject<boolean>(false); 
  condition$ = this.conditionSource.asObservable();
  private visonUrl = "https://dev.visionway";
  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService
  ) {

    this.headers = new HttpHeaders({
      'x-company-id': 'visionway-prodapsouth1-b2bandb2c',
      // 'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Methods': '*',
      // 'Content-Type': 'application/json'
    });
    if (!this.baseUrl) {
      this.baseUrl = environment.baseUrlNodeJS;
      this.checkUrl(this.baseUrl);
  
    
    }
  }

  set _baseUrl(baseUrl) {
    this.baseUrl = baseUrl;
  }
  checkUrl(url: string): void {
    const isValid = url.startsWith(this.baseUrl);
  

    const visionUrl = true;
  
    if (isValid === visionUrl) {
      this.setCondition(true);
    } else {
      this.setCondition(false);
    }
  }
  
  setCondition(value: boolean): void {
    this.conditionSource.next(value); 
  }
  
  get<IResponse>(
    slug: string,
    params: any = {},
    showToast: boolean = true
  ): Observable<IApiResponse<IResponse | any>> {
    return new Observable((observer: Subscriber<any>) => {
      this._http
        .get<IApiResponse<IResponse | any>>(`${this.baseUrl}${slug}`, {
          headers: this.headers,
          params: params,
        })
        .subscribe({
          next: (response) => {
            const result = response as IApiResponse<IResponse | any>;
            this.sendData(observer, result, showToast);
          },
          error: (err: HttpErrorResponse) => {
            console.log("incase of error => ", err);
            const e: IApiResponse<IResponse> =
              err && err.hasOwnProperty("error") ? err.error : null;
            this.sendData(observer, e, showToast);
          },
        });
    });
  }

  post(
    endpoint: string,
    payload: any,
    
    showToast: boolean = true
  ): Observable<any> {
    return new Observable((observer: Subscriber<any>) => {
      this._http
        .post<IApiResponse<any>>(`${this.baseUrl}${endpoint}`, payload, {
          headers:this.headers,
        })
        .subscribe({
          next: (response) => {
            const result = response as IApiResponse<any>;
            //console.log(response);
            this.sendData(observer, result, showToast);
          },
          error: (err: HttpErrorResponse) => {
            console.log("incase of error => ", err);
            const e: IApiResponse<any> =
              err && err.hasOwnProperty("error") ? err.error : {};
            this.sendData(observer, e, showToast);
          },
        });
    });
  }
  put(
    endpoint: string,
    payload: any,
    showToast: boolean = true
  ): Observable<any> {
    return new Observable((observer: Subscriber<any>) => {
      this._http
        .put<IApiResponse<any>>(`${this.baseUrl}${endpoint}`, payload, {
          headers: this.headers,
        })
        .subscribe({
          next: (response) => {
            const result = response as IApiResponse<any>;
            //console.log(response);
            this.sendData(observer, result, showToast);
          },
          error: (err: HttpErrorResponse) => {
            console.log("incase of error => ", err);
            const e: IApiResponse<any> =
              err && err.hasOwnProperty("error") ? err.error : null;
            this.sendData(observer, e, showToast);
          },
        });
    });
  }

  delete(endpoint: string, showToast: boolean = true): Observable<any> {
    return new Observable((observer: Subscriber<any>) => {
      this._http
        .delete(`${this.baseUrl}${endpoint}`, { headers: this.headers })
        .subscribe({
          next: (response) => {
            const result = response as IApiResponse<any>;
            this.sendData(observer, result, showToast);
          },
          error: (err: HttpErrorResponse) => {
            console.log("incase of error in delete api => ", err);
            const e: IApiResponse<any> =
              err && err.hasOwnProperty("error") ? err.error : null;
            this.sendData(observer, e, showToast);
          },
        });
    });
  }

  // for list type response only
  getList<IResponse>(
    endpoint,
    payload: any = {},
    showToast: boolean = true
  ): Observable<IApiResponse<IResponse | any>> {
    return new Observable((observer: Subscriber<any>) => {
      this._http
        .post<IApiResponse<IResponse | any>>(
          `${this.baseUrl}${endpoint}`,
          payload,
          { headers: this.headers }
        )
        .subscribe({
          next: (response) => {
            const result = response as IApiResponse<IResponse | any>;
            this.sendData(observer, result, showToast);
          },
          error: (err: HttpErrorResponse) => {
            console.log("incase of error => ", err);
            const e: IApiResponse<IResponse> =
              err && err.hasOwnProperty("error") ? err.error : null;
            this.sendData(observer, e, showToast);
          },
        });
    });
  }

  getListForRawURL<IResponse>(
    endpoint,
    payload: any = {},
    showToast: boolean = true
  ): Observable<IApiResponse<IResponse | any>> {
    return new Observable((observer: Subscriber<any>) => {
      this._http
        .post<IApiResponse<IResponse | any>>(`${endpoint}`, payload, {
          headers: this.headers,
        })
        .subscribe({
          next: (response) => {
            const result = response as IApiResponse<IResponse | any>;
            this.sendData(observer, result, showToast);
          },
          error: (err: HttpErrorResponse) => {
            console.log("incase of error => ", err);
            const e: IApiResponse<IResponse> =
              err && err.hasOwnProperty("error") ? err.error : null;
            this.sendData(observer, e, showToast);
          },
        });
    });
  }

  uploadFiles(formData: FormData, showToast: boolean = true): Observable<any> {
    return new Observable((observer: Subscriber<any>) => {
      const headers = new HttpHeaders({
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
      });

      // headers.append('Content-Type', 'multipart/form-data')
      this._http
        .post<IApiResponse<any>>(`${this.baseUrl}Common/UploadFile`, formData, {
          headers: headers,
        })
        .subscribe({
          next: (response) => {
            const result = response as IApiResponse<any>;
            this.sendData(observer, result, showToast);
          },
          error: (err: HttpErrorResponse) => {
            console.log("incase of error => ", err);
            const e: IApiResponse<any> =
              err && err.hasOwnProperty("error") ? err.error : null;
            this.sendData(observer, e, showToast);
          },
        });
    });
  }

  private sendData<IResponse>(
    observer: Subscriber<any>,
    result: IApiResponse<IResponse | any>,
    showToast: boolean = true
  ) {
    if (result?.status) {
      if (result.status.toLowerCase() === "ok") {
        const data = result.data ? result.data : result;
        observer.next(data);
      } else {
        let title = "Error";
        if (!result || (result && !result.status)) {
          title = "Internal Server issue";
          result = {
            status: "Error",
            error: "Something went wrong",
            errorCode: "500",
          };
        }

        if (showToast) {
					console.log(result.error, title)
        }

        observer.error(result);
      }
    } else {
      let title = "Error";
      if (!result || (result && !result.status)) {
        title = "Internal Server issue";
        // result = {
        // 	status: 'Error',
        // 	error: 'Something went wrong',
        // 	errorCode: '500'
        // }
        result["status"] = "Error";
        result["error"] = result["error"] || "Something went wrong";
      }

      if (showToast) {
        console.log(result.error, title)
      }

      observer.error(result);
    }

    observer.complete();
  }
  formDataPost(endpoint: string, payload: any) {
    const formData = new FormData();
    for (let key in payload) {
      
      formData.append(key, payload[key]);
    }

    return this._http.post(`${this.baseUrl}${endpoint}`, formData);
  }
  private setHeaders(): HttpHeaders {
    const headersConfig = {
      "Content-Type": "multipart/form-data",
      // Accept: 'application/json',
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Methods':'GET,POST, PUT, OPTIONS',
      // 'Access-Control-Allow-Headers':'Content-Type',
    };
    return new HttpHeaders(headersConfig);
  }
}
