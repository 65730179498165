import { Injectable, OnDestroy } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class WebSocketWhatsaapService implements OnDestroy {
  private socket: Socket;
  private readonly webSocketUrl = environment.baseUrlAutomation;

  constructor() {
    this.initializeSocketConnection();
  }

  private initializeSocketConnection(): void {
    this.socket = io(this.webSocketUrl, {
      transports: ['websocket'], 
    });
  }
 


  // Emit 'join-lead' event with payload
  emitJoinLeadEvent(companyId: string,userId: string, leadPhoneNumber: string): void {
    const payload = { companyId, userId, leadPhoneNumber };
    this.socket.emit('join-room', payload);
    console.log('Emitted join-lead event with payload:', payload);
  }
  emitReadMsg(companyId: string, messageId: string,chatId:string): void {
    const payload = { companyId, messageId,chatId };
    this.socket.emit('read_message', payload);
    console.log('Emitted join-lead event with payload:', payload);
  }
  emitJoinLivechat(companyId: string, userId: string){
        const payload = { companyId, userId };
    this.socket.emit('join-live-chat', payload);
    console.log('Emitted join-lead event with payload:', payload);

  }
  emitJoinleadLivechat(companyId: string, userId: string){
    const payload = { companyId, userId };
    this.socket.emit('join-lead-live-chat', payload);
    console.log('Emitted join-lead event with payload:', payload);

}
  // Emit 'leave-lead' event with payload
  emitLeaveLeadEvent(companyId: string, userId:string,leadPhoneNumber: string): void {
    const payload = { companyId,userId, leadPhoneNumber };
    this.socket.emit('leave-room', payload);
    console.log('Emitted leave-lead event with payload:', payload);
  }

  // Listen for 'receive-whatsapp-message' event
  onReceiveWhatsAppMessage(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('receive-whatsapp-message', (payload) => {
         console.log(payload,"payload")
        observer.next(payload);
      });

      // Clean up listener on unsubscribe
      return () => this.socket.off('receive-whatsapp-message');
    });
  }

  onLiveLeadChatUpdate(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on("lead-live-chat-update", (data) => {
        console.log("Received live-chat-update event:", data);
        observer.next(data);
      });
    });
  }
  onLiveChatUpdate(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on("live-chat-update", (data) => {
        console.log("Received live-chat-update event:", data);
        observer.next(data);
      });
    });
  }
  onWebSocketEvent(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on("read_message", (data) => {
        console.log("Received live-chat-update event:", data);
        observer.next(data);
      });
    });
  }
  
  


  // Close Socket connection
  closeConnection(): void {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  ngOnDestroy(): void {
    this.closeConnection();
  }
}
