import { Action, createReducer, on } from '@ngrx/store';
import { fetchChatEmployees, addNofificationChatEmployee, addChatEmployee, changeChatEmployeeStatus, addNofificationChatEmployeeOnLoadTime, removeNofificationChatEmployee } from './chat-employee.action';
import { chatEmployee, ChatEmp } from './chat-employee.model';
import { FirebaseMessage } from '../chatNotification/chat-notification.model';



export const initialState: ChatEmp[] = []

export const chatEmployeeReducer = createReducer(
  initialState,
  on(fetchChatEmployees, (state, { allchatEmployee }) => {
    let chatEmp =  allchatEmployee.filter((emp)=>  emp.participant &&  emp.participant.firstName  );
    return chatEmp;
  }),
  on(addChatEmployee, (state, { chatEmployee }) => {
    if(chatEmployee.participant && chatEmployee.participant.firstName ){
      return [...state, chatEmployee];
    }else {
       return state ;
    }
  }),
  on(changeChatEmployeeStatus, (state, { chatEmployeeId, status }) => {
    state.some((item: chatEmployee) => {
      if (item.participant.participantId == chatEmployeeId) {
        let participant =  {  ...item.participant , status : status }
        item.participant =  participant;
        return true;
      }
    });
    return state;
  }),
  on(addNofificationChatEmployee, (state, { notification }) => {
    if(notification.data.type && notification.data.type == 'group'){
      return state ;
    }
    let index = 0;
    state.some((item: chatEmployee, i: number) => {

      if (item.participant.participantId == notification.data.senderId) {
        let count = (item.nofitication && item.nofitication.count) ? item.nofitication.count + 1 : 1;
        item.nofitication = notification
        item.nofitication.count = count;
        index = i;

        return true;
      }
    });
    if (index) {
      return [...state.splice(index, 1), ...state];
    }

    return state;
  }),
  on(addNofificationChatEmployeeOnLoadTime, (state, { notificationArr }) => {

    notificationArr.forEach((item: FirebaseMessage) => {
      let index = 0;
      state.some((emp: chatEmployee, i) => {
        if (emp.participant.participantId == item.data.senderId) {
          emp.nofitication = item;
          index = i;
          return true;
        }
      });
      if (index) {
        state  =  [...state.splice(index, 1), ...state];
      }
    });
    return state;
  }),
  on(removeNofificationChatEmployee, (state, { roomId }) => {
    state.some((item: chatEmployee) => {

      if (item.roomId == roomId) {
        if (item.nofitication) {
          item.nofitication.data.content = '';
          item.nofitication.count = 0;
        }

        return true;
      }
    });
    return state;
  })

);

export function reducer(state: chatEmployee[], action: Action) {
  return chatEmployeeReducer(state, action);
}
