import { Injectable } from '@angular/core';
import { PbxDiallerService } from 'src/app/core/services/pbx-dialling.service';
import { PesonalChatSocketService } from './personalchat.socket.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public dashboardLeadStatus = "";
  public dashboardVisaType = "";
  public dashboardServiceInterest = "";
  public isDashboardRevisitCount = false;
  public isDashboardFollowUps = false;
  public isRevisit = false
  public isConvertedLeads = false;
  public employeeData: any;

  constructor(
    private _pbxDiallerService: PbxDiallerService,
    private _chatSocket: PesonalChatSocketService
  ) { }

  public ResetSearches() {
    this.dashboardLeadStatus = "";
    this.dashboardVisaType = "";
    this.dashboardServiceInterest = "";
    this.isDashboardRevisitCount = false;
    this.isDashboardFollowUps = false;
    this.isRevisit = false;
    this.isConvertedLeads = false;
  }
  updateEmployeeData(emp: any): void {
    this.employeeData = emp;
  }
  closeAllSockets(): void {
    if (this.employeeData) {
    //  let deviceToken: string = localStorage.getItem('deviceToken');
    //  this._chatSocket.emit('delete-token', { deviceToken: deviceToken, companyId: this.employeeData.companyId });
     // this._chatSocket.emit('update-status', { employeeId: this.employeeData.employeeId, status: 'offline' , companyId: this.employeeData.companyId });
    }
    localStorage.setItem('userStatus', '');
    localStorage.setItem('userStatus2', 'logout');
    this._chatSocket.disconnect();
    this._pbxDiallerService.destroyPBXSDK();
  }

}
