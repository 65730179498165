import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { init } from 'ys-webrtc-sdk-ui';
import { HttpServiceNode } from 'src/app/services/api/api.service';
import { IApiResponse } from '../models/generic.api.models';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class PbxDiallerService {

    phone: any;
    userName: string;
    sign: string;
    employeeData: any = null;
    check_hidden_class: boolean = false;
    callError: { [key: string]: string } = {
        "404": "The number you are trying to call is invalid or not found.",
        "480": "The call could not be answered as the recipient is temporarily unavailable.",
        "484": "The phone number format is incorrect or incomplete.",
        "486": "The recipient is currently busy and has declined the call.",
        "487": "The call was disconnected by the caller before being answered.",
        "603": "The call timed out as the recipient did not answer.",
    };

    destroyInstance: () => void; // Store the destroy function
    receiverName: string = '';
    receiverCrmNo: string = '';
    constructor(
        private _apiServiceNode: HttpServiceNode,
        private _toasterService: ToastrService,

    ) {}
    checkPBK(): void {
        //console.log('checkPBK .........')
        this.destroyPBXSDK();
        setTimeout(() => {
            this.initPBXSDK();
        }, 0)

    }
    initPBXSDK() {
        // this.destroyPBXSDK();

        const currentUser = JSON.parse(localStorage.getItem("currentUser"));
        this.employeeData =  currentUser ;
        this.userName = currentUser.officialEmail
        this.sign = currentUser.ivr_signature;
        const container = document.getElementById('callingdialler');
        // Initialization
        init(container, {
            username: this.userName,
            secret: this.sign,
            pbxURL: 'https://visionapplyivr.ras.yeastar.com',
            // sessionOption: {
            //     'sessionSetting': {
            //         x: '10',
            //         y: '40',
            //         video: true,
            //     }
            // },
            // video: true
        }).then(data => {
            // Obtain the exposed instances for additional business needs
            // const { pbx, destroy, on } = data;
            // this.phone = data.phone
            const { phone, pbx, destroy } = data;
            this.phone = phone;
            this.destroyInstance = destroy;
            // Create an RTC instance.
            // this.phone.on('newRTCSession', ({ callId, session }) => {
            //     const { status } = session
            //    console.log('abc' , 'newRTCSession' ,  status , callId)
            //     // Listen for events in the session.
            //     session.on('confirmed', ({ callId, session }) => {
            //         // A call is successfully connected, the 'session.status.callStatus' changes to 'talking'.
            //         // Update the user interface to start the call timer.
            //     })
            // })

            // Listen for the 'startSession' events.
            this.phone.on('startSession', ({ callId, session }) => {
                const { status } = session
                console.log('status nn', status)
                this.check_hidden_class = false;

                session.on('failed', (resp) => {
                    console.log("call failed .....", resp)
                    if (resp) {
                        this._toasterService.error(`${this.callError[resp.code] ?? resp.cause }`, "Calling Failure!");
                    }

                });
                if (status.number.length > 6) {
                    if (status.communicationType === 'outbound') {
                        if (this.receiverName) {
                            setTimeout(() => {
                                if (this.receiverCrmNo) {
                                    document.getElementsByClassName('hdr-name')[0].innerHTML = `<span class="ysd-tooltip-middle"> ${this.receiverCrmNo}   </span>`;
                                } else {
                                    document.getElementsByClassName('hdr-name')[0].innerHTML = `<span class="ysd-tooltip-middle"> ${this.receiverName}    </span>`;
                                }
                                document.getElementsByClassName('name')[0].innerHTML = `<span class="ysd-tooltip-middle"> ${this.receiverName} </span>`;
                                document.getElementById('callingdialler_call_hide_show_button').style.display = 'block';
                                console.log("call log button display ");

                            }, 0);
                        }
                        if(!this.receiverCrmNo){
                            document.getElementsByClassName('hdr-phone')[0].innerHTML = `<span class="ysd-tooltip-middle">  Unknown Call </span>`;
                            document.getElementsByClassName('hdr-name')[0].innerHTML = `<span class="ysd-tooltip-middle">   </span>`;
                            document.getElementsByClassName('name')[0].innerHTML = `<span class="ysd-tooltip-middle"> Unknown Call </span>`;

                        }else {
                            document.getElementsByClassName('hdr-phone')[0].innerHTML = `<span class="ysd-tooltip-middle">    </span>`;

                        }
   

                        // Outbound call.   
                        // Update the user interface to display 'Calling', indicating the callee side is ringing.



                    } else {

                        if (this.receiverName) {
                            setTimeout(() => {
                                if (this.receiverCrmNo) {
                                    document.getElementsByClassName('hdr-name')[0].innerHTML = `<span class="ysd-tooltip-middle"> ${this.receiverCrmNo}   </span>`;
                                } else {
                                    document.getElementsByClassName('hdr-name')[0].innerHTML = `<span class="ysd-tooltip-middle"> ${this.receiverName}    </span>`;
                                }
                                document.getElementsByClassName('name')[0].innerHTML = `<span class="ysd-tooltip-middle"> ${this.receiverName} </span>`;
                                document.getElementById('callingdialler_call_hide_show_button').style.display = 'block';


                            }, 0);
                        }
                        if(!this.receiverCrmNo){
                            document.getElementsByClassName('hdr-name')[0].innerHTML = `<span class="ysd-tooltip-middle">   </span>`;
                            document.getElementsByClassName('hdr-phone')[0].innerHTML = `<span class="ysd-tooltip-middle">  Unknown Call </span>`;
                            document.getElementsByClassName('name')[0].innerHTML = `<span class="ysd-tooltip-middle"> Unknown Call </span>`;


                        }else {
                            document.getElementsByClassName('hdr-phone')[0].innerHTML = `<span class="ysd-tooltip-middle">    </span>`;

                        }
                        // Inbound call.
                        // Update the user interface to display 'Connecting'.

                    }
                }



            });

            // Listen for Incoming call events.
            this.phone.on('incoming', ({ callId, session }) => {
                const { status } = session

                if (status.number.length > 6) {
                    setTimeout(() => {
                        if (document.getElementsByClassName('info')[0]) {
                            document.getElementsByClassName('info')[0].innerHTML = `<span class=""> Incoming Call  </span>`;
                        }

                    }, 0);
                    this.getParticularList(status.number);
                } else {
                    setTimeout(() => {
                        if (document.getElementsByClassName('info')[0]) {
                            document.getElementsByClassName('info')[0].innerHTML = `<span class=""> Internal Call  ${status.number}  </span>`;
                            document.getElementsByClassName('name')[0].innerHTML = `<span class="ysd-tooltip-middle"> ${this.receiverName} </span>`;
                        }
                    }, 0);
                }
                // Pop up an incoming call dialog displaying the caller's phone number and contact name on the User interface.
                // ...
                // Click the 'Answer' button to trigger the 'answer' method and the 'startSession' event.
                // this.phone.answer(callId);
            });
            // this.phone.disconnect();

            // After events subscription, start connecting to the SIP UA.
            //  phone.start();

            this.phone.on('deleteSession', () => {
                this.check_hidden_class = false;
                console.log("call disconnect .....")
                this._toasterService.error(``, "Call Disconnected !");
                document.getElementById('callingdialler_call_hide_show_button').style.display = 'none';
                this.receiverCrmNo = '';
                this.receiverName = '';
            });

        }).catch(err => {
            setTimeout(() => this.checkPBK(), 5000)
            console.log('err', err);
        })
    }
    destroyPBXSDK() {
        if (this.destroyInstance) {
            this.destroyInstance();

            //  console.log('PBX instance destroyed');
        } else {
            // console.warn('Destroy instance is not available');
        }
    }
    getParticularList(phoneNumber: string): void {

      
        const endpoint = `lead/email-phone/already-exists`;
        let payload =  {  phone: `+${phoneNumber}` } ;
        this._apiServiceNode
            .get(endpoint , payload)
            .subscribe({
                next: (response: any) => {
                    if (response) {

                        if (response.firstName) {
                            let name = ` ${response.firstName ?? ''}  ${response.lastName ?? ''} `;
                            this.receiverCrmNo = response.crmNo;
                            this.receiverName = name;
                            setTimeout(() => {
                                if (document.getElementsByClassName('info')[0]) {
                                    document.getElementsByClassName('info')[0].innerHTML = `<span class=""> ${this.receiverName} , </span> <div>  ${this.receiverCrmNo}  </div>`;

                                }

                            }, 0);
                        }
                    }

                },
                error: (err: IApiResponse<any>) => {
                    if(err.errorCode == 400){
                       console.log("number not found ")
                    }
                    console.log("error in api call pbx" , err);
                }
            });
    }


    callByPBX(mobile: string, receiverName: string = '', receiverCrmNo: string = ''): void {
        if (this.employeeData?.ivr_extension) {
            this.receiverName = receiverName;
            this.receiverCrmNo = receiverCrmNo;
            this.phone.call(mobile);
        } else {
            this.employeeData = JSON.parse(localStorage.getItem("currentUser"));
            if(this.employeeData?.ivr_extension){
                this.receiverName = receiverName;
                this.receiverCrmNo = receiverCrmNo;
                this.phone.call(mobile);
            }else {
                Swal.fire('Error', "you don't have call authority , please contact with management.", 'error');
            }
        }
    }
    hideAndShowCallModel(): void {
        const rtcWra = document.querySelector('.rtc-wra') as HTMLElement;
        if (rtcWra) {
            console.log('rtc class apply  ..........')
            if (this.check_hidden_class) {
                rtcWra.classList.remove('hidden');
                document.getElementById('callingdialler_call_hide_show_button').innerHTML = '<i class="bx bx-phone-call"></i> ';
            } else {
                rtcWra.classList.add('hidden');
                document.getElementById('callingdialler_call_hide_show_button').innerHTML = '<i class="bx bx-phone-call"></i> ';
            }
            this.check_hidden_class = !this.check_hidden_class;
        }
    }
}

