import { Component, OnInit, Output, effect, EventEmitter, Inject, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { AuthenticationService } from "../../core/services/auth.service";
import { AuthfakeauthenticationService } from "../../core/services/authfake.service";
import { environment } from "../../../environments/environment";
import { CookieService } from "ngx-cookie-service";
import { LanguageService } from "../../core/services/language.service";
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@ngrx/store";
import { Observable, Subscription } from "rxjs";
import { changesLayout } from "src/app/store/layouts/layout.actions";
import { getLayoutMode } from "src/app/store/layouts/layout.selector";
import { RootReducerState } from "src/app/store";
import { getUser } from "src/app/store/Authentication/authentication-selector";
import { IApiResponse } from "src/app/services/api/models";
import { HttpServiceNode } from "src/app/services/api/api.service";
import { NotificationService } from "src/app/pages/notify-module/notification.service";
import { PbxDiallerService } from "src/app/core/services/pbx-dialling.service";
import { SocketService } from "src/app/services/api/common-services/socket.service";
import { checkEmpData, fetchEmployeeData, updateEmplyeeStatus } from "src/app/store/employee/employee.action";
import Swal from 'sweetalert2';
import { PesonalChatSocketService } from "src/app/services/api/common-services/personalchat.socket.service";
import { FcmService } from "src/app/services/api/fcm.service";
//import { updateNotification } from "src/app/store/chatNotification/chat-notification.action";
import { ToastrService } from "ngx-toastr";
import { CommonService } from "src/app/services/api/common-services/common.service";
import { Group, GroupList, RecentChatEmployee } from "src/app/pages/chat/chat.model";
import { addChatEmployee, addNofificationChatEmployeeOnLoadTime, changeChatEmployeeStatus, fetchChatEmployees } from "src/app/store/chatEmployee/chat-employee.action";
import { FirebaseMessage } from "src/app/store/chatNotification/chat-notification.model";
import { insertNotification, makeNotificationRead } from "src/app/store/chatNotification/chat-notification.action";
import { ManageCompanyApiService } from "src/app/services/api/manage.company.api.service";
import { SharedMethodsService } from "src/app/services/api/common-services/shared-methods.service";
import { WebSocketWhatsaapService } from "src/app/pages/lead/lead-grid/whatsaap-chat.service";
import { MarketingAutomationApiService } from "src/app/services/api/marketing.automation.api.service";
import { CommunicationService } from "src/app/pages/live-chat/chat/share.service";
import { LeadCommunicationService } from "src/app/pages/lead/lead-grid/lead-service-update-count";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';





@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
  animations: [
    trigger('badgeIncrement', [
      transition(':increment', [
        style({ transform: 'scale(1)' }),
        animate(
          '0.3s ease-in-out',
          style({ transform: 'scale(1.5)' })
        ),
        animate('0.2s ease-in-out', style({ transform: 'scale(1)' })),
      ]),
    ]),
  ],
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit, OnDestroy {
  private groupListSubscription: Subscription | undefined;
  groupList: Group[] = [];
  mode: any;
  totalNotifications: number = 0;
  totalNotifications1: any;
  element: any;
  cookieValue: any;
  flagvalue: any;
  countryName: any;
  valueset: any;
  theme: any;
  layout: string;
  dataLayout$: Observable<string>;
  user$: Subscription;
  phone: any;
  logo: string | null = null;
  employeeState$;
  // Define layoutMode as a property
  employeeData: any = null;
  messagesNotifications = [];
  totalNotificationCount = 0;


  statusSelectObj = [
    { name: 'Online', val: 'online' },
    { name: 'Offline', val: 'offline' },
    { name: 'On Break', val: 'onBreak' },
    { name: 'On Leave', val: 'onLeave' },
  ];
  currentUserStatus: string = 'online';
  currentUserStatusName: string = 'Online';
  statusClass = {
    online: 'text-success',
    offline: 'text-danger',
    onBreak: 'text-info',
    onLeave: 'text-warning'
  };
  socketId = '';
  statusSelectedName = {
    online: 'Online',
    offline: 'Offline',
    onBreak: 'On Break',
    onLeave: 'On Leave'
  }
  private visibilityChangeHandler!: () => void;
  currentUser: any;
  contactCount: any;
  leadDataCount: any;
  totalcount: any;


  constructor(
    @Inject(DOCUMENT) private document: any,
    private _apiService: HttpServiceNode,
    private router: Router,
    private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    private notificationService: NotificationService,
    public store: Store<RootReducerState>,
    private _pbxDiallerService: PbxDiallerService,
    private _socketClient: SocketService,
    private _chatSocket: PesonalChatSocketService,
    private _toasterService: ToastrService,
    private commonService: CommonService,
    private _companyApi: ManageCompanyApiService,
    private sharedService: SharedMethodsService,
    // private webSocketService:WebSocketWhatsaapService ,
    private _fcmService: FcmService,
    private httpNodemarketing: MarketingAutomationApiService,
    private communicationService: CommunicationService,
    private LeadCommunicationService: LeadCommunicationService,
    private webSocketService: WebSocketWhatsaapService
  ) {
    this.employeeData = JSON.parse(localStorage.getItem("currentUser"));
    this.webSocketService.emitJoinleadLivechat(
      this.employeeData.companyId,
      this.employeeData.employeeId,
    );
    const employeeData = JSON.parse(localStorage.getItem("currentUser"));
    this.webSocketService.emitJoinLivechat(
      employeeData.companyId,
      employeeData.employeeId
    );
    const currentUser = localStorage.getItem("currentUser");
    const user = JSON.parse(currentUser);
    const companyId = user.companyId;

    this.getCompanyById(companyId);

    this.employeeData = JSON.parse(localStorage.getItem('currentUser'));
    // console.log("emp ", this.employeeData)

    this.getCountLevel();
    this.getAllcontactcount();
    this.getAllLeadCount();
  }

  listLang: any = [
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    { text: "Spanish", flag: "assets/images/flags/spain.jpg", lang: "es" },
    { text: "German", flag: "assets/images/flags/germany.jpg", lang: "de" },
    { text: "Italian", flag: "assets/images/flags/italy.jpg", lang: "it" },
    { text: "Russian", flag: "assets/images/flags/russia.jpg", lang: "ru" },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();


  ngOnInit() {
    // this.initialAppState = initialState;
    this.currentUserStatusName = this.statusSelectedName[localStorage.getItem('userStatus') ?? 'online'];

    this.sharedService.logoUrl.subscribe(res => {
      this.logo = res;
    })

    this.store.select("layout").subscribe((data) => {
      this.theme = data.DATA_LAYOUT;
    });
    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.cookieValue = this._cookiesService.get("lang");
    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "assets/images/flags/us.jpg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
    this.user$ = this.store.select(getUser).subscribe((data) => {
      //console.log(data, "data");
    });
    this.notificationService.notificationCount$.subscribe((count) => {

      this.totalNotifications1 = count;
      this.getCountLevel();
    });

    const callingDialler = document.getElementById('callingdialler');
    if (this.employeeData.ivr_extension) {
      this._pbxDiallerService.checkPBK();
      callingDialler.style.display = 'block';
    } else {
      callingDialler.style.display = 'none';
    }

    this._chatSocket.setConnection();

    // this.fcmService.listenForMessages().subscribe(
    //   (message) => {

    //     //this.messagesNotifications.push(message) ;
    //     this.store.dispatch(updateNotification({ notification: message }));
    //     this._toasterService.info(
    //       message.data.content,
    //       message.data.firstName
    //     );
    //     //this.store<ChatNoti>.
    //   },
    //   (error) => {
    //     console.error('Error while listening for notifications: ................................................................', error);
    //   });

    this._chatSocket.emit('get-chat-list', { userId: this.employeeData.employeeId, companyId: this.employeeData.companyId });
    this._chatSocket.emit('get-groups', { userId: this.employeeData.employeeId, companyId: this.employeeData.companyId });


    this.commonService.updateEmployeeData(this.employeeData);
    this._chatSocket.listen('receive-chat-list').subscribe((item: RecentChatEmployee[]) => {
      //console.log("receive-chat-list " , item);
      this.store.dispatch(fetchChatEmployees({ allchatEmployee: item }));
      this._chatSocket.emit('message-count', { employeeId: this.employeeData.employeeId, companyId: this.employeeData.companyId });
    });
    this._chatSocket.listen('new_user_added').subscribe((item: any) => {
      //console.log("new user is add you" , item)
      this.store.dispatch(addChatEmployee({ chatEmployee: item }))
    });
    this._chatSocket.listen('chat-error').subscribe((item: any) => {
      console.log("error in socket ", item)
    });
    this._chatSocket.listen('receive_notification').subscribe((item: any) => {
      //  console.log(" new socket notification come ", item);
      this._fcmService.handleNotificationUpdate(item);
    });
    this._chatSocket.listen('update-data').subscribe((item: any) => {
       //console.log("someone chnage status");
      try {
        if (item) {
          if (item.employeeId != this.employeeData.employeeId) {
            this.store.dispatch(updateEmplyeeStatus({ employeeId: item.employeeId, workStatus: item.workStatus }));
            this.store.dispatch(changeChatEmployeeStatus({ chatEmployeeId: item.employeeId, status: item.workStatus }));
          } else {
             let userStatus = localStorage.getItem('userStatus2');
             if(userStatus && userStatus == 'logout'){
                 this.logout();
             }else {
               if(item.workStatus != 'offline'){
                this.updateCurrentStatusForMultipleTab({ val: item.workStatus, name: this.statusSelectedName[item.workStatus] });
                this.store.dispatch(updateEmplyeeStatus({ employeeId: item.employeeId, workStatus: item.workStatus }));
               }
             }
          }
        }
      } catch (err) {
        console.log("error ", err)
      }
    });
    this._chatSocket.listen('groups-data').subscribe((item: GroupList) => {
      try {
        if (item.success) {
          this._chatSocket.updateGroupList(item.groups);
          this._chatSocket.emit('group-msg-count', { userId: this.employeeData.employeeId, companyId: this.employeeData.companyId });
        } else {
          console.log("error in group chat");
        }
      } catch (error) {

      }
    });
    this._chatSocket.listen('msg-count-group').subscribe((item: any) => {
      // total group message 
    

      if (item && item.totalUnreadMessages) {
        this._chatSocket.updateGroupNotificationInStart(item.unreadMessageCount, item.unreadLastMessage);


      }

    });
    this._chatSocket.listen('count-message').subscribe((item: any) => {
       
      let notificationModel: FirebaseMessage[] = item.map((msg: any) => {
        let obj: FirebaseMessage = {
          from: '',
          data: {
            content: msg.content,
            contentType: msg.contentType,
            firstName: msg.employeeName,
            senderId: msg.employeeId,
            profilePicUrl: msg.profilePicUrl,
            roomId: msg.roomId,
            body: '',
            title: '',
            type: msg.type,
          },
          count: msg.count
        }
        return obj;
      });
      this.store.dispatch(insertNotification({ notification: notificationModel }));
      this.store.dispatch(addNofificationChatEmployeeOnLoadTime({ notificationArr: notificationModel }));
      this.store.select("ChatNotification").subscribe((data) => {
        this.messagesNotifications = data;
      });
    });
    this.store.dispatch(fetchEmployeeData());

    this.visibilityChangeHandler = () => {
      //console.log("visibilityChangeHandler @@@@@@@@@@@@@@@@@@")
      if (!document.hidden) {
        // let roomId  =  this._fcmService.getRoomId();
        // this._chatSocket.emit('join-room', { roomId: roomId, companyId: this.employeeData.companyId });
        // this._chatSocket.emit('get-all-messages', {  userId: this.employeeData.employeeId ,  roomId: roomId, companyId: this.employeeData.companyId });
        // this.store.dispatch(makeNotificationRead({ roomId: roomId }));
      } else {
        let roomId = this._fcmService.getRoomId();
        this._chatSocket.emit('typing', { userId: this.employeeData.employeeId, roomId: roomId, isTyping: false });
        this._fcmService.updateRoomId('');
        // this._chatSocket.emit('leave-room', { roomId: roomId, userId: this.employeeData.employeeId });
        if (roomId) {
          this._chatSocket.leaveRoom(roomId);
        }

      }
    };

    document.addEventListener('visibilitychange', this.visibilityChangeHandler);
    setInterval(() => {
      let userStatus = localStorage.getItem('userStatus');
      if (userStatus) {
        if (userStatus == 'offline') {
          this._chatSocket.emit('update-status', {
            employeeId: this.currentUser?.employeeId,
            status: 'online',
            companyId: this.currentUser?.companyId
          });
        } else {
          this._chatSocket.emit('update-status', {
            employeeId: this.currentUser?.employeeId,
            status: userStatus,
            companyId: this.currentUser?.companyId
          });
        }

      }
    }, 15000)



    this.groupListSubscription = this._chatSocket.getGroupListNotification().subscribe((groupList) => {
      this.groupList = groupList;
    });

    const currentUserString = localStorage.getItem('currentUser');
    this.currentUser = currentUserString ? JSON.parse(currentUserString) : null;
    // this.initialize();
    // this._scrollElement();
    this.communicationService.message$.subscribe((message) => {
      this.getAllcontactcount();
    });
    this.LeadCommunicationService.message$.subscribe((message) => {
      this.getAllLeadCount();
    });




    if (
      this.currentUser.roleName === "Admin" ||
      this.currentUser.roleName === "Counsellor" ||
      this.currentUser.roleName === "Branch Manager" ||
      this.currentUser.roleName === "Data Analytics" ||
      this.currentUser.roleName === "BPO Lead Head" ||
      this.currentUser.roleName === "BPO Lead Telle caller" ||
      this.currentUser.roleName === "Front Desk Executive"   ||
      this.currentUser.roleName === "BPO Team Leader"     

    ) {
      this.webSocketService.onLiveLeadChatUpdate().subscribe(() => {
        //console.log("Triggered lead-live-chat-update event.");
        this.LeadCommunicationService.emitMessage('Update count');
        this.getAllLeadCount();
        this.showLeadWelcomeNotification()
      });

    }
    if (
      this.currentUser.roleName === "Admin" ||
      this.currentUser.roleName === "Data Analytics"
    ) {
      this.webSocketService.onLiveChatUpdate().subscribe(() => {
        //console.log("Triggered live-chat-update event.");
        this.communicationService.emitMessage('Update count');
        this.showWelcomeNotification();
        this.getAllcontactcount();

      });
    }

  }
  incrementCount() {
    this.totalcount++;
  }

  updateCurrentStatus(status: { name: string, val: string }): void {
    localStorage.setItem('userStatus', status.val);
    this.currentUserStatus = status.val;
    this.currentUserStatusName = status.name;
    this._socketClient.userStatus$.next(status.val);
    this._chatSocket.emit('update-status', { employeeId: this.employeeData.employeeId, status: this.currentUserStatus, companyId: this.employeeData.companyId });
  }
  updateCurrentStatusForMultipleTab(status: { name: string, val: string }): void {
    localStorage.setItem('userStatus', status.val);
    this.currentUserStatus = status.val;
    this.currentUserStatusName = status.name;
    this._socketClient.userStatus$.next(status.val);

  }


  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }


  /**
   * Logout the user
   */
  logout() {
    //let sameBrowserCheck =  localStorage.getItem('abc');
    // check socket is same when we active on other pages on same web
    let deviceId = localStorage.getItem('deviceId') ?? '';
    let endpoint = `employee/logout/${this.employeeData.employeeId}`;
    let deviceToken = localStorage.getItem('deviceToken');
    let payload = {
      deviceId, deviceToken
    }
    this._apiService.put(endpoint, payload).subscribe({
      next: (response: any) => {
        sessionStorage.removeItem('PREVIOUS_NUMBER');
        this.webSocketService.closeConnection();
        if (environment.defaultauth === "firebase") {
          this.authService.logout();
        } else {
          this.authFackservice.logout();
        }
        this.router.navigate(["/auth/login"]);
      },
      error: (err) => {
        if (environment.defaultauth === "firebase") {
          this.authService.logout();
        } else {
          this.authFackservice.logout();
        }
        this.router.navigate(["/auth/login"]);
        console.log("error ", err);
        // console.error('Error fetching services:', err);
      }
    });
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle("fullscreen-enable");
    if (
      !document.fullscreenElement &&
      !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement
    ) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  changeLayout(layoutMode: string) {
    this.theme = layoutMode;
    this.store.dispatch(changesLayout({ layoutMode }));
    this.store.select(getLayoutMode).subscribe((layout) => {
      document.documentElement.setAttribute("data-layout", layout);
    });
  }
  gotoNotify() {
    this.router.navigate(["/notification"]);
  }
  getCountLevel(): void {

    let currentUser = localStorage.getItem("currentUser");
    let empId = currentUser ? JSON.parse(currentUser).employeeId : null;

    const url = "notification/notification/counts";
    const formData = {
      employeeId: empId,
    };

    this._apiService.get(url, formData).subscribe({
      next: (response: any) => {

        if (response) {
          this.totalNotifications = response.total;
        }
      },
      error: (err: IApiResponse<any>) => {
        console.error("Error fetching notification counts", err);
      },
    });
  }

  openProfile() {
    this.router.navigate(["company-profile"]);
  }

  getCompanyById(companyId: string) {
    const endpoint = `company/get/id/${companyId}`;
    this._companyApi.get(endpoint).subscribe({
      next: (response: any) => {
        this.logo = response?.avatarUrl || 'N/A';
      },
      error: (err: IApiResponse<any>) => {
        console.error('Error fetching company details:', err);
      },
    });
  }


  showHideCall(): void {
    this._pbxDiallerService.hideAndShowCallModel();
  }
  ngOnDestroy(): void {
    document.removeEventListener('visibilitychange', this.visibilityChangeHandler);
    this.groupListSubscription?.unsubscribe();
    // // this._socketClient.userStatus$.unsubscribe();
  }

  goToChatPage(): void {
    this.router.navigate(['/chat']);
  }

  //Whatsapp Chat link
  goToWhatsappChatPage(): void {
    this.router.navigate(['/chat-lead']);
  }


  goToWhatsappContactPage(): void {
    this.router.navigate(['/live-chat/chat']);

  }
  getAllcontactcount() {
    const employeeData = JSON.parse(localStorage.getItem("currentUser"));
    const payload = {
      pageNumber: 1,
      pageSize: 1000,
    };

    this.httpNodemarketing.get("contacts/unread-message").subscribe(
      (response) => {
        this.contactCount = response.count;
        this.getAddSumboth()
      },
      (error) => {
        console.error("API Error:", error);
      }
    );
  }
  getAllLeadCount() {
    const employeeData = JSON.parse(localStorage.getItem("currentUser"));
    const payload = {
      employeeId: employeeData.employeeId,
    };

    this.httpNodemarketing
      .post("whatsapp-message/unread/total-count", payload)
      .subscribe(
        (response) => {
          this.leadDataCount = response.count;
          this.getAddSumboth()
        },
        (error) => {
          console.error("API Error:", error);
        }
      );
  }
  getAddSumboth() {
    this.totalcount = this.leadDataCount + this.contactCount
  }


  showWelcomeNotification() {
    if ('Notification' in window) {
      // Check if notifications are denied
      if (Notification.permission === 'denied') {
        alert(
          'Notifications are blocked in your browser settings. Please enable notifications manually by clicking the lock icon in the address bar.'
        );
        return;
      }

      // Check if permission is already granted
      if (Notification.permission === 'granted') {
        this.displayNotification();
      } else if (Notification.permission === 'default') {
        // Request notification permission
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            this.displayNotification();
          } else {
            alert(
              'Notifications are not enabled. Please allow notifications in your browser settings.'
            );
          }
        });
      }
    } else {
      console.warn('Browser does not support notifications.');
    }
  }


  displayNotification() {
    const notification = new Notification('VisionWay!', {
      body: 'New Contact chat message, please check.',
      icon: 'https://cdn-icons-png.flaticon.com/512/561/561127.png', // Chat icon
      requireInteraction: true,
    });
    setTimeout(() => {
      notification.close();
    }, 2000);
    // Optional: Play a sound
    try {
      const audio = new Audio('assets/sound/mixkit-fairy-message-notification-861.wav');
      audio.play().catch((error) => {
        console.error('Audio playback failed:', error);
      });
    } catch (error) {
      console.error('Audio initialization failed:', error);
    }

    // Handle notification click
    notification.onclick = () => {
      //console.log('Notification clicked!');
      window.open('/live-chat/chat', '_blank'); // Open in a new tab
      notification.close(); // Close the notification
    };
  }



  showLeadWelcomeNotification() {
    if ('Notification' in window) {
      // Check if notifications are denied
      if (Notification.permission === 'denied') {
        alert(
          'Notifications are blocked in your browser settings. Please enable notifications manually by clicking the lock icon in the address bar.'
        );
        return;
      }

      // Check if permission is already granted
      if (Notification.permission === 'granted') {
        this.displayLeadNotification();
      } else if (Notification.permission === 'default') {
        // Request notification permission
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            this.displayNotification();
          } else {
            alert(
              'Notifications are not enabled. Please allow notifications in your browser settings.'
            );
          }
        });
      }
    } else {
      console.warn('Browser does not support notifications.');
    }
  }

  displayLeadNotification() {
    const notification = new Notification('VisionWay!', {
      body: 'New Lead chat message, please check.',
      icon: 'https://cdn-icons-png.flaticon.com/512/561/561127.png', // Chat icon
      requireInteraction: true,
    });
    setTimeout(() => {
      notification.close();
    }, 2000);
    // Optional: Play a sound
    try {
      const audio = new Audio('assets/sound/mixkit-fairy-message-notification-861.wav');
      audio.play().catch((error) => {
        console.error('Audio playback failed:', error);
      });
    } catch (error) {
      console.error('Audio initialization failed:', error);
    }

    // Handle notification click
    notification.onclick = () => {
      //console.log('Notification clicked!');
      window.open('/lead', '_blank'); // Open in a new tab
      notification.close(); // Close the notification
    };
  }
  countNotification() {
    this.totalNotificationCount = this.messagesNotifications.length + this.groupList.length;

  }

}
